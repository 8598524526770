export const PERMISSIONS = {
  VIEW_GRAPHE: 'view_graphe',
  VIEW_APP_REPORT: 'view_app_report',
  VIEW_APP_LOT_GAGNANT: 'view_app_lot_gagnant',
  VIEW_STATISTIQUE: 'view_statistique',
  BLOCK_TIRAGE: 'block_tirage',
  BLOCK_SUP: 'block_sup',
  CHANGE_PASSWORD_SUP: 'change_password_sup',
  BLOCK_SUC: 'block_suc',
  BLOCK_AGENT: 'block_agent',
  CHANGE_PASSWORD_AGENT: 'change_password_agent',
  BLOCK_BOULE: 'block_boule',
  BLOCK_OPTION: 'block_option',
  BLOCK_LIMITE_GAME: 'block_limite_game',
  BLOCK_LIMITE_GAME_AGENT: 'block_limite_game_agent',
  BLOCK_LIMITE_BOULE: 'block_limite_boule',
  BLOCK_LIMITE_BOULE_AGENT: 'block_limite_boule_agent',
  VIEW_REPORT_VENTE: 'view_report_vente',
  VIEW_REPORT_PARTIEL: 'view_report_partiel',
  VIEW_REPORT_AGENT: 'view_report_agent',
  VIEW_LOT_GAGNANT_PARTIEL: 'view_lot_gagnant_partiel',
  REACTIVATE_FICHE: 'reactivate_fiche',
  VIEW_FICHE_WIN: 'view_fiche_win',
  VIEW_FICHE_DELETE: 'view_fiche_delete',
  CHANGE_LOGO: 'change_logo',
  OPTION_PLUS: 'option_plus',
  MARIAGE_FREE: 'mariage_free',
  CHANGE_PASSWORD: 'change_password',
  CHANGE_USER_INFO: 'change_user_info'
};
